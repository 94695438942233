/**
 * @generated SignedSource<<8f5888f59a1017f63e7389eeeaca05e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type PanelArmingSystem = "AP" | "AREA" | "HA" | "HSA" | "HSAWG";
export type PanelHardwareModel = "CELLCOM" | "CELLCOM_EX" | "CELLCOM_SL" | "DUALCOM" | "EPAD" | "ICOM" | "ICOM_E" | "ICOM_LNC" | "ICOM_NRL" | "ICOM_SL" | "MINI_CELLCOM" | "NOTIFYI" | "SCS101" | "SCS150" | "TMS6" | "VIDEO_ONLY" | "X001" | "XF6_100" | "XF6_500" | "XR10" | "XR100" | "XR100N" | "XR150" | "XR20" | "XR200" | "XR2400F" | "XR350" | "XR40" | "XR5" | "XR500" | "XR500N" | "XR550" | "XR6" | "XRSuper6" | "XT30" | "XT30L" | "XT50" | "XT50L" | "XT75" | "XTL" | "XTLN" | "XTLP" | "XTLW";
import { FragmentRefs } from "relay-runtime";
export type SystemStateModel_controlSystem$data = {
  readonly address1: string;
  readonly address2: string;
  readonly areas: {
    readonly " $fragmentSpreads": FragmentRefs<"areaModel_areaConnection">;
  };
  readonly city: string;
  readonly controlSystemGroups: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly country: string;
  readonly customer: {
    readonly id: string;
  };
  readonly doorsConnection: {
    readonly " $fragmentSpreads": FragmentRefs<"doorModel_controlSystemDoorsConnection">;
  } | null;
  readonly hasLegacyTempUser: boolean;
  readonly id: string;
  readonly isBillingControlSystem: boolean;
  readonly isTMSentry: boolean;
  readonly isX1: boolean;
  readonly isXf: boolean;
  readonly isXr: boolean;
  readonly isXt: boolean;
  readonly isXt75: boolean;
  readonly maxSchedulesPerDoor: number;
  readonly outputsConnection: {
    readonly " $fragmentSpreads": FragmentRefs<"TrackedOutputModel_controlSystemOutputsConnection">;
  };
  readonly panel: {
    readonly armingSystem: PanelArmingSystem;
    readonly hardwareModel: PanelHardwareModel;
    readonly legacyPanelId: string;
    readonly softwareDate: string;
    readonly softwareVersion: string;
  };
  readonly requiresUserCode: boolean;
  readonly sensorActivityZones: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly number: string;
  }>;
  readonly servicesManager: {
    readonly " $fragmentSpreads": FragmentRefs<"controlSystemServicesModel_servicesManager">;
  };
  readonly site: {
    readonly id: string;
  } | null;
  readonly supportsActiveUserCodes: boolean;
  readonly supportsPushNotifications: boolean;
  readonly supportsSempro: boolean;
  readonly univiewEnabled: boolean | null;
  readonly vkSystemDisplayName: string;
  readonly wifiSetupEnabled: boolean;
  readonly " $fragmentType": "SystemStateModel_controlSystem";
};
export type SystemStateModel_controlSystem$key = {
  readonly " $data"?: SystemStateModel_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemStateModel_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "SystemStateModel_controlSystem"
};

(node as any).hash = "054ab81239c2a0703feecbf76c9aaa01";

export default node;
