import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefinedOrEmpty } from "common/utils/universal/function";
import {
  controlSystemArmingType,
  hardwareModel,
  hasLegacyTempUser,
  isTmSentry,
  isX1,
  isXf,
  isXr,
  isXt,
  isXt75,
  legacyPanelId,
  maxSchedulesPerDoor,
  requiresUserCode,
  supportsPushNotifications,
  supportsSempro,
  wifiSetupEnabled,
} from "common/utils/universal/scapi-helpers/control-systems";
import Maybe from "data.maybe";
import { Map, OrderedMap, Record, Seq, Set } from "immutable";
import { convertGqlDataToReduxData as convertAreasGqlDataToReduxData } from "models/area";
import { createPanelCapabilities } from "models/capabilities";
import {
  convertGqlDataToReduxData as convertServicesManagerGqlDataToReduxData,
  createControlSystemServices,
  createControlSystemServicesFromJson,
} from "models/controlSystemServices";
import {
  convertGqlDataToReduxData as convertDoorsGqlDataToReduxData,
  createDoorsFromJson,
} from "models/door";
import { createPanelPermissions } from "models/panelPermissions";
import {
  convertGqlDataToReduxData as convertTrackedOutputsGqlDataToReduxData,
  createTrackedOutputsFromJson,
} from "models/TrackedOutput";
import { compose, curry, defaultTo, map, path, prop } from "ramda";
import { readInlineData } from "react-relay";
import {
  fromControlSystemId,
  fromCustomerId,
  fromSiteId,
  fromZoneId,
} from "securecom-graphql/client";
import { getOrElse, toInt } from "utils";
import { isTakeoverPanel } from "utils/panel";
import { initialState as initialAreasState } from "../reducer/areas";
import { initialState as initialArmingState } from "../reducer/arming";
import { initialState as initialBarrierOperatorsState } from "../reducer/barrierOperators";
import {
  createDoorsState,
  initialState as initialDoorsState,
} from "../reducer/doors";
import { initialState as initialEventsState } from "../reducer/events";
import { initialState as initialFavoritesState } from "../reducer/favorites";
import { initialState as initialHolidayDatesState } from "../reducer/holidayDates";
import { initialState as initialLightsState } from "../reducer/lights";
import { initialState as initialLocksState } from "../reducer/locks";
import { initialState as initialOnDemandState } from "../reducer/onDemand";
import { initialState as initialOutputOptionsState } from "../reducer/outputOptions";
import { initialState as initialOutputsState } from "../reducer/outputs";
import { initialState as initialProfilesState } from "../reducer/profiles";
import { initialState as initialXrSchedulesState } from "../reducer/schedules/xr";
import { initialState as initialXtArmingScheduleState } from "../reducer/schedules/xt/arming";
import { initialState as initialXtFavoriteSchedulesState } from "../reducer/schedules/xt/favorite";
import { initialState as initialXtOutputSchedulesState } from "../reducer/schedules/xt/output";
import { initialState as initialSystemAreaInformationState } from "../reducer/systemAreaInformation";
import { initialState as initialSystemOptionsState } from "../reducer/systemOptions";
import { initialState as initialThermostatsState } from "../reducer/thermostats";
import { initialState as initialUserProgrammableActionsState } from "../reducer/userProgrammableActions";
import { initialState as initialUsersState } from "../reducer/users";
import { initialState as initialZoneInformationState } from "../reducer/zoneInformation";
import { initialState as initialZwaveState } from "../reducer/zwave";

//If you ever need to add a new panel, you must update values that are based on software versions in common/utils/universal/scapi-helpers/control-systems.ts and also in GQL ControlSystems. If not, you won't be able to enter your user code or see any menu items for your system.

export const PANEL_HARDWARE_MODELS = {
  XT30: "XT30",
  XT50: "XT50",
  XTLP: "XTLP",
  XR150: "XR150",
  XR350: "XR350",
  XR550: "XR550",
  CellComSL: "CellComSL",
  iComSL: "iComSL",
  iComLNC: "iComLNC",
  DualCom: "DualCom",
  MiniCellCom: "MiniCellCom",
  CellComEx: "CellComEX",
  X1: "X001",
  XF6_500: "XF6_500",
  XF6_100: "XF6_100",
  XT75: "XT75",
  TMS6: "TMS6",
};

export const XT_PANEL_FAMILY = "XT30";
export const XR_PANELS_FAMILY = "XR550";
export const XF_PANELS_FAMILY = "XF6_500";
export const XT75_PANEL_FAMILY = "XT75";
export const TMS6_PANEL_FAMILY = "TMS6";

const createSensorActivityZone = Record({
  id: null,
  name: "",
  number: null,
});

const normalizeSensorActivityZones = (json) =>
  Seq(json)
    .map((item) =>
      createSensorActivityZone({
        id: item.id,
        name: item.name,
        number: parseInt(item.number, 10),
      })
    )
    .sortBy(prop("name"))
    .reduce((acc, item) => acc.set(item.number, item), OrderedMap());

export const createSystemState = Record({
  id: 0,
  name: "",
  nickname: "",
  city: "",
  country: "",
  panelId: "",
  siteId: null,
  customerId: 0,
  address1: "",
  address2: "",
  isXT: false,
  isXR: false,
  isX1: false,
  isXf: false,
  isXt75: false,
  isTmSentry: false,
  isTakeoverPanel: false,
  isBillingControlSystem: false,
  requiresUserCode: true,
  wifiSetupEnabled: false,
  hasLegacyTempUser: false,
  hasUserActive: false,
  maxSchedulesPerDoor: 8,
  hardwareModel: "",
  supportsPushNotifications: false,
  supportsSempro: false,
  armingType: "",
  trackedOutputs: Map(),
  trackedDevices: Map(),
  groupIds: Set(),
  permissions: createPanelPermissions(),
  capabilities: createPanelCapabilities(),
  capabilitiesReceived: false,
  servicesManager: createControlSystemServices(),
  userCode: "",
  userCodeValidated: false,
  saveUserCode: false,
  sessionRefreshedAt: 0,
  requestingCapabilities: false,
  requestingLockdown: false,
  lockdownError: null,
  requestingSensorReset: false,
  sensorResetError: null,
  connectionTimeout: false,
  softwareVersion: "0",
  softwareDate: "",
  selectedXtSchedule: null,
  arming: initialArmingState,
  events: initialEventsState,
  lights: initialLightsState,
  locks: initialLocksState,
  barrierOperators: initialBarrierOperatorsState,
  doors: initialDoorsState,
  outputs: initialOutputsState,
  thermostats: initialThermostatsState,
  outputOptions: initialOutputOptionsState,
  favorites: initialFavoritesState,
  profiles: initialProfilesState,
  areas: initialAreasState,
  zwave: initialZwaveState,
  xrSchedules: initialXrSchedulesState,
  xtArmingSchedule: initialXtArmingScheduleState,
  xtFavoriteSchedules: initialXtFavoriteSchedulesState,
  xtOutputSchedules: initialXtOutputSchedulesState,
  systemOptions: initialSystemOptionsState,
  systemAreaInformation: initialSystemAreaInformationState,
  zoneInformation: initialZoneInformationState,
  onDemand: initialOnDemandState,
  holidayDates: initialHolidayDatesState,
  users: initialUsersState,
  userProgrammableActions: initialUserProgrammableActionsState,
  sensorActivityZones: Map(),
  univiewEnabled: false,
});
const hasAtLeastSoftwareVersion = curry(
  (version, json) => softwareVersionFromControlSystemJson(json) >= version
);
const createTrackedOutputsFromControlSystemJson = compose(
  getOrElse(OrderedMap()),
  map(createTrackedOutputsFromJson),
  Maybe.fromNullable,
  path(["panels", 0, "tracked_outputs"])
);
const createTrackedDevicesFromControlSystemJson = compose(
  getOrElse(OrderedMap()),
  map(createDoorsFromJson),
  Maybe.fromNullable,
  path(["panels", 0, "devices"])
);
const createDoorsFromControlSystemJson = compose(
  createDoorsFromJson,
  Seq.Indexed,
  defaultTo([]),
  path(["panels", 0, "devices"])
);
const softwareVersionFromControlSystemJson = compose(
  getOrElse(0),
  map(toInt),
  Maybe.fromNullable,
  path(["panels", 0, "software_version"])
);

export const isVideoOnly = (system) =>
  system.hardwareModel.toUpperCase() === "VIDEO ONLY";

export const isSiteSystem = (system) => !!system.siteId;

export const createSystemStateFromJson = (json) =>
  createSystemState({
    panelId: legacyPanelId(json),
    siteId: json.site_id,
    customerId: json.customerId,
    id: toInt(json.id),
    name: isNotNullOrUndefinedOrEmpty(json.nickname)
      ? json.nickname
      : json.name,
    city: json.city || "",
    country: json.country || "",
    address1: json.address1 || "",
    address2: json.address2 || "",
    groupIds: Maybe.fromNullable(json.group_ids).map(Set).getOrElse(Set()),
    isXT: isXt(json),
    isXR: isXr(json),
    isX1: isX1(json),
    isXf: isXf(json),
    isXt75: isXt75(json),
    isTmSentry: isTmSentry(json),
    isTakeoverPanel: isTakeoverPanel(json),
    hardwareModel: hardwareModel(json),
    requiresUserCode: requiresUserCode(json),
    wifiSetupEnabled: wifiSetupEnabled(json),
    supportsPushNotifications: supportsPushNotifications(json),
    supportsSempro: supportsSempro(json),
    armingType: controlSystemArmingType(json),
    servicesManager: createControlSystemServicesFromJson(
      json.services_managers[0]
    ),
    trackedOutputs: createTrackedOutputsFromControlSystemJson(json),
    trackedDevices: createTrackedDevicesFromControlSystemJson(json),
    doors: createDoorsState({
      byNumber: createDoorsFromControlSystemJson(json),
    }),
    maxSchedulesPerDoor: maxSchedulesPerDoor(json),
    hasLegacyTempUser: hasLegacyTempUser(json),
    hasUserActive: isXr(json) && hasAtLeastSoftwareVersion(172, json),
    sensorActivityZones: normalizeSensorActivityZones(
      json.panels[0].sensor_activity_zones
    ),
    softwareVersion: json.panels[0].software_version
      ? json.panels[0].software_version.toString()
      : "0",
    softwareDate: json.panels[0].software_date,
    isBillingControlSystem: json.site_billing_control_system,
    univiewEnabled: json.services_managers[0].uniview_analytics_enabled,
  });

export const normalizePanelsAndControlSystemsJson = (controlSystems) => {
  return Seq(controlSystems).map(createSystemStateFromJson);
};
export const foldSystems = (systems) =>
  systems.reduce((acc, system) => acc.set(system.id, system), OrderedMap());

/**
 * @param { import("./__generated__/SystemStateModel_controlSystem.graphql").SystemStateModel_controlSystem$key } key
 * @returns {ReturnType<createSystemState>}
 */
export const convertGqlDataToReduxData = (key) => {
  const data = readInlineData(
    graphql`
      fragment SystemStateModel_controlSystem on ControlSystem @inline {
        id
        vkSystemDisplayName
        city
        country
        address1
        address2
        isXt
        isXr
        isX1
        isXf
        isXt75
        isTMSentry
        requiresUserCode
        wifiSetupEnabled
        supportsPushNotifications
        supportsSempro
        maxSchedulesPerDoor
        hasLegacyTempUser
        supportsActiveUserCodes
        isBillingControlSystem
        univiewEnabled
        panel {
          hardwareModel
          armingSystem
          softwareVersion
          softwareDate
          legacyPanelId
        }
        servicesManager {
          ...controlSystemServicesModel_servicesManager
        }
        outputsConnection {
          ...TrackedOutputModel_controlSystemOutputsConnection
        }
        doorsConnection {
          ...doorModel_controlSystemDoorsConnection
        }
        areas(sort: { keys: ["number"], order: ASC }) {
          ...areaModel_areaConnection
        }
        sensorActivityZones {
          id
          name
          number
        }
        controlSystemGroups {
          id
        }
        customer {
          id
        }
        site {
          id
        }
      }
    `,
    key
  );
  const dbId = Number(fromControlSystemId(data?.id).systemId);
  const trackedDevices = convertDoorsGqlDataToReduxData(data?.doorsConnection);

  return createSystemState({
    panelId: data?.panel?.legacyPanelId,
    siteId: data?.site ? Number(fromSiteId(data?.site.id).siteId) : null,
    customerId: Number(fromCustomerId(data?.customer.id).customerId),
    id: dbId,
    name: data?.name,
    city: data?.city,
    country: data?.country,
    address1: data?.address1,
    address2: data?.address2,
    groupIds: Seq(data?.controlSystemGroups).map(prop("id")).toSet(),
    isXT: data?.isXt,
    isXR: data?.isXr,
    isXf: data?.isXf,
    isXt75: data?.isXt75,
    isTmSentry: data?.isTMSentry,
    isX1: data?.isX1,
    isTakeoverPanel: data?.isTakeoverPanel,
    hardwareModel: data?.panel?.hardwareModel,
    requiresUserCode: data?.requiresUserCode,
    wifiSetupEnabled: data?.wifiSetupEnabled,
    supportsPushNotifications: data?.supportsPushNotifications,
    supportsSempro: data?.supportsSempro,
    armingType: data?.panel?.armingSystem,
    servicesManager: convertServicesManagerGqlDataToReduxData(
      data?.servicesManager
    ),
    trackedOutputs: convertTrackedOutputsGqlDataToReduxData(
      dbId,
      data?.outputsConnection
    ),
    trackedDevices,
    doors: createDoorsState({
      byNumber: trackedDevices,
    }),
    areas: convertAreasGqlDataToReduxData(data?.areas),
    maxSchedulesPerDoor: data?.maxSchedulesPerDoor,
    hasLegacyTempUser: data?.hasLegacyTempUser,
    hasUserActive: data?.supportsActiveUserCodes,
    sensorActivityZones: Seq(data?.sensorActivityZones)
      .map((item) =>
        createSensorActivityZone({
          id: Number(fromZoneId(item.id).number), // TODO: Make sure this doesn't need to be a real DB ID
          name: item.name,
          number: item.number,
        })
      )
      .sortBy(prop("name"))
      .reduce((acc, item) => acc.set(item.number, item), OrderedMap()),
    softwareVersion: data?.panel?.softwareVersion,
    softwareDate: data?.panel?.softwareDate,
    isBillingControlSystem: data?.isBillingControlSystem,
    univiewEnabled: data?.univiewEnabled,
  });
};
